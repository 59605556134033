<template>
  <div class="add__task">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="md-12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="md-12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Add a Task
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="addTaskForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitTaskForm">
        <b-card
          class="px-3 py-2"
        >
          <b-row>
            <b-col cols="md-9">
              <b-form-group
                label="Task Name*"
                label-for="h-task-block-taskName"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Task Name"
                  vid="taskName"
                  rules="required"
                >
                  <b-form-input
                    id="h-task-block-taskName"
                    v-model="taskName"
                    placeholder="Task Name"
                    :state="errors.length > 0 ? false : null"
                    name="taskName"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-9">
              <b-form-group
                label="Remarks"
                label-for="h-task-block-taskRemarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Remarks"
                  vid="taskRemarks"
                  rules=""
                >
                  <b-form-textarea
                    id="h-task-block-taskRemarks"
                    v-model="taskRemarks"
                    placeholder="Remarks"
                    :state="errors.length > 0 ? false : null"
                    name="taskRemarks"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-row
          class="content-header v-sticky-sidebar-container"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2 top-info-right"
            cols="12"
            md="4"
          >
            <vue-sticky-sidebar
              class="sidebar"
              :top-spacing="topSpacing"
              container-selector=".v-sticky-sidebar-container"
              inner-wrapper-selector=".sidebar__inner"
            >
              <b-card
                id=""
                no-body
                class="padding-two p-1"
              >
                <p>Start by selecting the base task block below.</p>
                <b-card
                  v-for="(task, tkey) in taskBlocks"
                  id=""
                  :key="tkey"
                  no-body
                  class=""
                >
                  <div class="padding-one p-1 shadow-background">
                    <b-row>
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="Base Task Block"
                          vid="task-block"
                          rules="required"
                        >
                          <b-form-group
                            label="Base Task Block"
                            label-for="event-custom-field-task-block"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <v-select
                              id="event-custom-field-task-block"
                              v-model="task.block"
                              label="name"
                              placeholder="Select from list"
                              :options="taskBlockOptions"
                              :reduce="name => name._id"
                              :clearable="false"
                              @input="setTaskBlock(tkey)"
                            >
                              <template #option="{ icon, name, color }">
                                <feather-icon
                                  :icon="icon"
                                  size="16"
                                  class="align-middle mr-50"
                                  :style="{ '--bg-color': color }"
                                />
                                <span> {{ name }}</span>
                              </template>

                              <template #selected-option="{ icon, name, color }">
                                <feather-icon
                                  :icon="icon"
                                  size="16"
                                  class="align-middle mr-50"
                                  :style="{ '--bg-color': color }"
                                />
                                <span> {{ name }}</span>
                              </template>
                            </v-select>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row
                      v-for="(opt, key) in task.blockFull.fields"
                      :key="key"
                    >
                      <b-col cols="12">
                        <b-form-group
                          v-if="opt.type == 'text' && key == 0"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="opt.label"
                            :vid="'event-custom-field-' + key"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >
                            <b-form-input
                              :id="'event-custom-field-' + key"
                              v-model="task.fieldValues[key].value"
                              disabled
                              :placeholder="opt.placeholder"
                              :state="(errors.length > 0) ? false : null"
                            />
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-if="opt.type == 'text' && key != 0"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="opt.label"
                            :vid="'event-custom-field-' + key"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >
                            <b-form-input
                              :id="'event-custom-field-' + key"
                              v-model="task.fieldValues[key].value"
                              :placeholder="opt.placeholder"
                              :state="(errors.length > 0) ? false : null"
                            />
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-if="opt.type == 'textarea-normal'"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="opt.label"
                            :vid="'event-custom-field-' + key"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >
                            <b-form-textarea
                              :id="'event-custom-field-' + key"
                              v-model="task.fieldValues[key].value"
                              trim
                              :placeholder="opt.placeholder"
                              :state="(errors.length > 0) ? false : null"
                            />
                          </validation-provider>
                        </b-form-group>

                        <b-form-group
                          v-if="opt.type == 'textarea-rich'"
                          :label="opt.label"
                          :label-for="'event-custom-field-' + key"
                        >
                          <validation-provider
                            :name="opt.label"
                            :vid="'event-custom-field-' + key"
                            :rules="opt.required == 'yes' ? 'required' : ''"
                          >
                            <!-- <b-form-textarea
                              :id="'event-custom-field-' + key"
                              v-model="task.fieldValues[key].value"
                              trim
                              :placeholder="opt.placeholder"
                              :state="(errors.length > 0) ? false : null"
                            /> -->
                            <quill-editor
                              v-model="task.fieldValues[key].value"
                              :options="{ modules: { toolbar: `#toolbar-${tkey}`, }, placeholder: 'Compose message', }"
                            >
                              <div
                                :id="'toolbar-'+tkey"
                                slot="toolbar"
                              >
                                <!-- Add a bold button -->
                                <button class="ql-bold">
                                  Bold
                                </button>
                                <button class="ql-italic">
                                  Italic
                                </button>
                                <button class="ql-underline">
                                  Underline
                                </button>
                                <select class="ql-size">
                                  <option value="small" />
                                  <!-- Note a missing, thus falsy value, is used to reset to default -->
                                  <option selected />
                                  <option value="large" />
                                  <option value="huge" />
                                </select>

                                <select class="ql-align">
                                  <option selected="selected" />
                                  <option value="center" />
                                  <option value="right" />
                                  <option value="justify" />
                                </select>
                              </div>
                            </quill-editor>
                          </validation-provider>
                        </b-form-group>

                        <validation-provider
                          v-if="opt.type == 'select'"
                          #default="{ errors }"
                          :name="opt.label"
                          :vid="'event-custom-field-' + key"
                          :rules="opt.required == 'yes' ? 'required' : ''"
                        >
                          <b-form-group
                            :label="opt.label"
                            :label-for="'event-custom-field-' + key"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <v-select
                              :id="'event-custom-field-' + key"
                              v-model="task.fieldValues[key].value"
                              label="value"
                              :placeholder="opt.placeholder"
                              :options="opt.extraOptions"
                              :reduce="value => value.key"
                              :clearable="false"
                              @input="setRecipients(tkey, key)"
                            />
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          v-if="opt.type == 'multi-select'"
                          #default="{ errors }"
                          :name="opt.label"
                          :vid="'event-custom-field-' + key"
                          :rules="opt.required == 'yes' ? 'required' : ''"
                        >
                          <b-form-group
                            :label="opt.label"
                            :label-for="'event-custom-field-' + key"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <v-select
                              :id="'event-custom-field-' + key"
                              v-model="task.fieldValues[key].value"
                              label="value"
                              multiple
                              :placeholder="opt.placeholder"
                              :options="opt.extraOptions"
                              :reduce="value => value.key"
                              :clearable="false"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col
                        v-if="opt.type == 'select' && ['pre-defined recipient', 'data-source', 'another block'].includes(task.fieldValues[key].value)"
                        cols="md-12"
                      >
                        <div
                          v-for="(field, rindex) in task.customFields"
                          :key="rindex"
                        >
                          <b-form-group
                            v-if="field.type == 'tag'"
                            :label="field.label"
                            :label-for="'h-recipients-fields' + rindex"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Label"
                              vid="label"
                              :rules="field.required == 'yes' ? 'required' : ''"
                            >
                              <b-form-tags
                                v-model="field.value"
                                input-id="tags-separators"
                                separator=";"
                                :placeholder="field.placeholder"
                                :tag-validator="(...args) => validator(...args, task)"
                                class="mb-2"
                                :state="(errors.length > 0) ? false : null"
                              />
                            </validation-provider>
                          </b-form-group>

                          <validation-provider
                            v-if="field.type == 'select'"
                            #default="{ errors }"
                            :name="field.label"
                            :vid="'h-recipients-fields-' + rindex"
                            :rules="field.required == 'yes' ? 'required' : ''"
                          >
                            <b-form-group
                              :label="field.label"
                              :label-for="'h-recipients-fields-' + rindex"
                              :state="(errors.length > 0) ? false : null"
                            >
                              <v-select
                                :id="'h-recipients-fields-' + rindex"
                                v-model="field.value"
                                label="value"
                                :placeholder="field.placeholder"
                                :options="field.extraOptions"
                                :reduce="value => value.key"
                                :clearable="false"
                                @input="setFinalRecipients(tkey, key, rindex)"
                              />
                            </b-form-group>
                          </validation-provider>
                          <b-form-group
                            v-if="field.type == 'auto-suggest'"
                            :label="field.label"
                            :label-for="'h-recipients-fields' + rindex"
                          >
                            <validation-provider
                              #default="{}"
                              name="Label"
                              vid="label"
                              :rules="field.required == 'yes' ? 'required' : ''"
                            >
                              <vue-autosuggest
                                :suggestions="field.extraOptions"
                                :limit="10"
                                :input-props="{ id: 'autosuggest__input', class: 'form-control', placeholder: field.placeholder }"
                                @selected="(...args) => setBlock(...args, tkey, key, rindex)"
                                @input="(...args) => suggestOnInputChange(...args, tkey, key, rindex)"
                              >
                                <template slot-scope="{suggestion}">
                                  <span class="my-suggestion-item">{{ suggestion.item }}</span>
                                </template>
                              </vue-autosuggest>
                            </validation-provider>
                          </b-form-group>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="task.blockFull.name == 'Forms'">
                      <b-col cols="12">
                        <b-form-group
                          label="Select Forms"
                        >
                          <validation-provider
                            #default="{}"
                            name="Select Forms"
                            rules="required"
                          >
                            <b-form-checkbox
                              v-for="(option, key) in formOptions"
                              :key="key"
                              v-model="task.fieldValues[task.blockFull.fields.length].value"
                              :value="option._id"
                              name="flavour-3a"
                            >
                              {{ option.name }}
                            </b-form-checkbox>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row v-if="task.blockFull.name == 'Inventory Request'">
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="Form"
                          vid="h-recipients-fields-form-options"
                          rules=""
                        >
                          <b-form-group
                            label="This Form will draw data from this IR Block"
                            label-for="h-recipients-fields-form-options"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <v-select
                              id="h-recipients-fields-form-options"
                              v-model="task.fieldValues[task.blockFull.fields.length].value"
                              label="name"
                              placeholder="Select an Option"
                              :options="formOptions.filter(o => o.name == 'Redemption of Imported Urn Form')"
                              :reduce="value => value._id"
                              :clearable="true"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <div
                          class="d-flex flex-row"
                          align-v="center"
                        >
                          <b-button
                            v-if="taskBlocks.length > 1"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="button"
                            variant="flat-danger"
                            class="primary-button-with-background no-background float-right"
                            @click="removeFromTask(tkey)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              class="cursor-pointer red-trash-button"
                            />
                          </b-button>

                          <div class="action_button ml-auto">
                            <b-button
                              v-show="tkey != 0"
                              variant="flat-primary"
                              @click="moveTask(tkey, tkey-1)"
                            >
                              <feather-icon
                                icon="ArrowUpIcon"
                                class="mr-50"
                                size="16"
                              />
                            </b-button>
                            <b-button
                              v-show="tkey != (taskBlocks.length - 1)"
                              variant="flat-primary"
                              @click="moveTask(tkey, tkey+1)"
                            >
                              <feather-icon
                                icon="ArrowDownIcon"
                                class="mr-50"
                                size="16"
                              />
                            </b-button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    v-if="task.blockFull.name == 'Form' || task.blockFull.name == 'Input Fields'"
                    class="p-1 purple-row"
                  >
                    <b-row>
                      <b-col
                        v-if="task.customFields.length > 0"
                        md="12"
                        class=""
                      >
                        <span class="py-1">{{ task.customFields.length }} field(s) added</span>
                        <b-button
                          v-b-modal.add-input-fields
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant="flat-primary"
                          class="primary-button-with-background no-background float-right"
                          @click="setTaskBlockCustomFields(tkey)"
                        >
                          <feather-icon
                            icon="EditIcon"
                            class=""
                            size="20"
                          />
                        </b-button>
                      </b-col>
                      <b-col
                        v-else
                        md="12"
                      >
                        <b-button
                          v-b-modal.add-input-fields
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="button"
                          variant=""
                          class="primary-button-with-background purple-button"
                          @click="setTaskBlockCustomFields(tkey)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">Add Field</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </b-card>
              </b-card>
              <div
                v-if="taskBlocks[taskBlocks.length - 1].block"
                class="add_task_footer"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  @click="addNewTaskBlock"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                    size="16"
                  />
                  <span class="align-middle">Add a New Block</span>
                </b-button>
              </div>
            </vue-sticky-sidebar>
          </b-col>

          <!-- Content Right -->
          <b-col
            class="content-header-right d-md-block d-none mb-1"
            md="8"
            cols="12"
          >
            <b-card
              v-if="taskBlocks[taskBlocks.length - 1].block || taskBlocks.length > 1"
              no-body
              class="mb-50 padding-two background-card scroll-item-1"
            >
              <b-row
                class="mb-1"
              >

                <!-- Content Left -->
                <b-col
                  class="content-header-left top-info-right"
                  cols="12"
                  md="6"
                >
                  <h4>Preview</h4>
                </b-col>
              </b-row>
              <b-card
                no-body
                class="mb-50 scroll-item-1"
              >
                <template #header>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="flat-primary"
                    class="primary-button-with-background mr-3"
                  >
                    <feather-icon
                      :icon="taskBlocks.length > 1 ? 'GridIcon' : (taskBlocks[0].blockFull.icon ? taskBlocks[0].blockFull.icon : '')"
                      class=""
                      size="20"
                    />
                  </b-button>
                  <h3 class="align-middle mr-auto">
                    {{ taskName }}
                  </h3>
                </template>
                <div
                  v-for="(task, tkey) in taskBlocks"
                  id=""
                  :key="tkey"
                >
                  <b-card
                    v-if="task.blockFull.name == 'Send Email With Attachment'"
                    no-body
                    class="mb-50 padding-one"
                  >
                    <h4 class="ml-auto mt-1">
                      #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                    </h4>
                    <email-template
                      :input-values="task.fieldValues"
                      :custom-fields="task.customFields"
                    />
                  </b-card>
                  <b-card
                    v-else-if="task.blockFull.name == 'File Upload'"
                    no-body
                    class="mb-50 padding-one"
                  >
                    <h4 class="ml-auto mt-1">
                      #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                    </h4>
                    <file-upload-template
                      :input-values="task.fieldValues"
                    />
                  </b-card>
                  <b-card
                    v-else-if="task.blockFull.name == 'Send SMS Message'"
                    no-body
                    class="mb-50 padding-one"
                  >
                    <h4 class="ml-auto mt-1">
                      #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                    </h4>
                    <send-sms-template
                      :input-values="task.fieldValues"
                      :custom-fields="task.customFields"
                    />
                  </b-card>
                  <b-card
                    v-else-if="task.blockFull.name == 'Send Telegram Message'"
                    no-body
                    class="mb-50 padding-one"
                  >
                    <h4 class="ml-auto mt-1">
                      #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                    </h4>
                    <send-telegram-message-template
                      :input-values="task.fieldValues"
                      :custom-fields="task.customFields"
                    />
                  </b-card>
                  <b-card
                    v-else-if="task.blockFull.name == 'Form' || task.blockFull.name == 'Input Fields'"
                    no-body
                    class="mb-50 padding-one"
                  >
                    <h4 class="ml-auto mt-1">
                      #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                    </h4>
                    <input-field-template
                      :input-values="task.fieldValues"
                      :fields="task.customFields"
                    />
                  </b-card>
                  <b-card
                    v-else-if="task.blockFull.name == 'Forms'"
                    no-body
                    class="mb-50 padding-one"
                  >
                    <h4 class="ml-auto mt-1">
                      #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                    </h4>
                    <form-template
                      :input-values="task.fieldValues"
                      :fields="task.customFields"
                      :form-options="formOptions"
                    />
                  </b-card>
                  <b-card
                    v-else-if="task.blockFull.name == 'Link'"
                    no-body
                    class="mb-50 padding-one"
                  >
                    <h4 class="ml-auto mt-1">
                      #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                    </h4>
                    <link-template
                      :input-values="task.fieldValues"
                      :fields="task.customFields"
                    />
                  </b-card>
                  <b-card
                    v-else-if="task.blockFull.name == 'Verification'"
                    no-body
                    class="mb-50 padding-one"
                  >
                    <h4 class="ml-auto mt-1">
                      #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                    </h4>
                    <verification-template
                      :input-values="task.fieldValues"
                      :fields="task.customFields"
                    />
                  </b-card>
                  <b-card
                    v-else-if="task.blockFull.name == 'Inventory Request'"
                    no-body
                    class="mb-50 scroll-item-1"
                  >
                    <!-- <h4 class="ml-auto mt-1">
                      #{{ inputValues[0] ? inputValues[0].value : '' }}
                    </h4> -->
                    <div class="d-flex justify-content-between mt-1">
                      <h4 style="font-weight: 500; font-size: 15px; color: #000000;">
                        Status: <span class="text-capitalize">{{ task.fieldValues[4] ? (task.fieldValues[4].value ? task.fieldValues[4].value : '-') : '-' }}</span>
                      </h4>
                      <h4 class="">
                        #{{ task.fieldValues[0] ? task.fieldValues[0].value : '' }}
                      </h4>
                    </div>
                    <inventory-request-template
                      :input-values="task.fieldValues"
                    />
                  </b-card>
                  <b-card
                    v-else
                    no-body
                    class="mb-50 padding-one"
                  >
                    <p>Nothing to Preview</p>
                  </b-card>
                </div>
              </b-card>
            </b-card>
            <b-card
              v-else
              no-body
              class="mb-50 padding-two padding-one"
            >
              <b-row>
                <b-col>
                  <div class="empty_block task_empty">
                    <h4>No preview yet</h4>
                    <span class="align-middle">Preview of the Base Task Block you selected will be showing up here.</span>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'operation-workflows-show', params: { id: $route.params.parent } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Task</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>

    <b-modal
      id="block-all-modal-update-status"
      ref="update-status-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="submitForm"
    >
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="workFlowStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>

    <b-modal
      id="add-input-fields"
      ref="add-input-fields-modal"
      cancel-variant="flat-primary"
      modal-class="status-update-modal"
      ok-variant="success"
      ok-title="Done"
      cancel-title="Cancel"
      centered
      size="lg"
      no-close-on-backdrop
      title="Fields"
      @ok="setFinalCustomFields"
    >
      <b-form @submit.prevent="setFinalCustomFields">
        <validation-observer
          ref="customFieldsForm"
        >
          <b-row
            v-for="(field, index) in customFields"
            :key="index"
            ref="row"
            class="field__group"
          >
            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Field Type"
                vid="fieldType"
                rules="required"
              >
                <b-form-group
                  label="Field Type*"
                  :label-for="'h-activity-type-input-type' + index"
                  label-cols-md="4"
                  :state="(errors.length > 0 || field.inputTypeInvalid) ? false : null"
                >
                  <v-select
                    :id="'h-activity-type-input-type' + index"
                    v-model="field.type"
                    label="title"
                    :options="inputTypeOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="updateExtraFileds(index)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="field.inputTypeInvalid"
                    class="text-danger"
                  >
                    {{ field.inputTypeInvalidMessage }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <div
                class="row"
              >
                <b-col cols="md-12">
                  <b-form-group
                    label="Label*"
                    :label-for="'h-activity-type-label' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <b-form-textarea
                        :id="'h-activity-type-label' + index"
                        v-model="field.label"
                        placeholder="Field Label"
                        :state="(errors.length > 0 || field.labelValidation) ? false : null"
                        name="label"
                        @input="field.labelValidation == true ? field.labelValidation = false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.labelValidation"
                        class="text-danger"
                      >
                        {{ field.labelValidationMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="md-12">
                  <b-form-group
                    label="Placeholder"
                    :label-for="'h-activity-type-description' + index"
                    label-cols-md="4"
                  >
                    <b-form-input
                      :id="'h-activity-type-placeholder' + index"
                      v-model="field.placeholder"
                      placeholder="Field Placeholder"
                      name="placeholder"
                      @input="field.placeholderValidation == true ? field.placeholderValidation = false : null"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="field.type == 'radio-multiple' || field.type == 'radio-single'"
                  cols="md-12"
                >
                  <b-form-group
                    label="Add Selection Option*"
                    :label-for="'h-activity-type-extraOptions' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      v-for="(opt, key) in field.extraOptions"
                      :key="key"
                      #default="{ errors }"
                      name="Label"
                      vid="label"
                      rules="required"
                    >
                      <div class="inlineElement mb-2">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            :id="'h-activity-type-label' + index + 'extraOptions' + key"
                            v-model="opt.value"
                            placeholder="Enter an option name"
                            :state="(errors.length > 0 || opt.valueValidation) ? false : null"
                            name="value"
                            @input="opt.valueValidation == true ? opt.valueValidation = false : null"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              icon="XIcon"
                              class="cursor-pointer"
                              @click="removeCustomFieldOptions(index, key)"
                            />
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="opt.valueValidation"
                        class="text-danger"
                      >
                        {{ opt.valueValidationMessage }}
                      </small>
                    </validation-provider>
                    <p
                      class="addOptionText"
                      @click="addCustomFieldOptions(index)"
                    >
                      Add an Option
                    </p>
                  </b-form-group>
                </b-col>

                <b-col
                  v-if="field.type == 'datasource-select'"
                  cols="md-12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Datasource"
                    vid="datasource"
                    rules="required"
                  >
                    <b-form-group
                      label="Datasource*"
                      :label-for="'h-activity-type-label' + index + 'extraOptions0'"
                      label-cols-md="4"
                      :state="(errors.length > 0 || field.extraOptions[0].valueValidation) ? false : null"
                    >
                      <v-select
                        :id="'h-activity-type-label' + index + 'extraOptions0'"
                        v-model="field.extraOptions[0].value"
                        label="title"
                        :options="dataSourceOptions"
                        :reduce="title => title.code"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.extraOptions[0].valueValidation"
                        class="text-danger"
                      >
                        {{ field.extraOptions[0].valueValidationMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="field.type == 'file'"
                  cols="md-12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Upload File Type"
                    vid="fileType"
                    rules="required"
                  >
                    <b-form-group
                      label="Upload File Type*"
                      :label-for="'h-activity-type-label' + index + 'extraOptions0'"
                      label-cols-md="4"
                      :state="(errors.length > 0 || field.extraOptions[0].valueValidation) ? false : null"
                    >
                      <v-select
                        :id="'h-activity-type-label' + index + 'extraOptions0'"
                        v-model="field.extraOptions[0].value"
                        label="title"
                        :options="fileTypeOptions"
                        :reduce="title => title.code"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.extraOptions[0].valueValidation"
                        class="text-danger"
                      >
                        {{ field.extraOptions[0].valueValidationMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  v-if="field.type == 'block-select'"
                  cols="md-12"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Reference Block ID"
                    vid="reference"
                    rules="required"
                  >
                    <b-form-group
                      label="Reference Block ID*"
                      :label-for="'h-activity-type-label' + index + 'extraOptions0'"
                      label-cols-md="4"
                      :state="(errors.length > 0 || field.extraOptions[0].valueValidation) ? false : null"
                    >
                      <v-select
                        :id="'h-activity-type-label' + index + 'extraOptions0'"
                        v-model="field.extraOptions[0].value"
                        label="value"
                        :options="taskListOfWorkFlow"
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.extraOptions[0].valueValidation"
                        class="text-danger"
                      >
                        {{ field.extraOptions[0].valueValidationMessage }}
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="md-12">
                  <b-form-group
                    label="Required?*"
                    :label-for="'h-activity-type-required' + index"
                    label-cols-md="4"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Required"
                      vid="required"
                      rules="required"
                    >
                      <b-form-radio-group
                        :id="'h-activity-type-required' + index"
                        v-model="field.required"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="field.required === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="field.requiredInvalid"
                        class="text-danger"
                      >
                        {{ field.requiredInvalidMessage }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </div>
            </b-col>

            <b-col cols="12">
              <div
                class="d-flex flex-row"
                align-v="center"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-danger"
                  class="primary-button-with-background no-background float-right"
                  @click="removeCustomField(index)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="cursor-pointer red-trash-button"
                  />
                </b-button>

                <div class="action_button ml-auto">
                  <b-button
                    v-show="index != 0"
                    variant="flat-primary"
                    @click="moveField(index, index-1)"
                  >
                    <feather-icon
                      icon="ArrowUpIcon"
                      class="mr-50"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-show="index != (customFields.length - 1)"
                    variant="flat-primary"
                    @click="moveField(index, index+1)"
                  >
                    <feather-icon
                      icon="ArrowDownIcon"
                      class="mr-50"
                      size="16"
                    />
                  </b-button>
                </div>
              </div>
              <hr class="dividerHR">
            </b-col>
          </b-row>
          <b-button
            v-if="customFields.length > 0"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            @click="addCustomField"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="align-middle">Add a New Field</span>
          </b-button>
          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <h4>No item yet</h4>
                <b-button
                  type="button"
                  variant="flat-primary"
                  @click="addCustomField"
                >
                  <span class="align-middle">Add a New Field</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vueStickySidebar from 'vue-sticky-sidebar'
import {
  BCard, BRow, BButton, BCol, BForm, BFormGroup, BFormInput, BFormTextarea, BFormRadio, BFormRadioGroup,
  BInputGroupAppend, BInputGroup, BNav, BFormTags, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import 'array.prototype.move'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import moment from 'moment'

import EmailTemplate from '../task-block-component/EmailTemplate.vue'
import FileUploadTemplate from '../task-block-component/FileUploadTemplate.vue'
import SendSmsTemplate from '../task-block-component/SendSmsTemplate.vue'
import SendTelegramMessageTemplate from '../task-block-component/SendTelegramMessageTemplate.vue'
import InputFieldTemplate from '../task-block-component/InputFieldTemplate.vue'
import FormTemplate from '../task-block-component/FormTemplate.vue'
import LinkTemplate from '../task-block-component/LinkTemplate.vue'
import VerificationTemplate from '../task-block-component/VerificationTemplate.vue'
import InventoryRequestTemplate from '../task-block-component/InventoryRequestTemplate.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BFormRadioGroup,
    BInputGroupAppend,
    BInputGroup,
    BNav,
    BFormTags,
    BFormCheckbox,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    VueAutosuggest,
    'vue-sticky-sidebar': vueStickySidebar,

    EmailTemplate,
    FileUploadTemplate,
    SendSmsTemplate,
    SendTelegramMessageTemplate,
    InputFieldTemplate,
    FormTemplate,
    LinkTemplate,
    VerificationTemplate,
    InventoryRequestTemplate,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      topSpacing: 135,
      block: {},
      status: 'active',
      statusValidation: false,
      savedData: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],
      currentTasks: [],
      blockCount: 0,
      taskName: '',
      taskBlocks: [{
        block: '',
        blockFull: {},
        fieldValues: [],
        customFields: [],
        createdAt: moment().utcOffset(process.env.VUE_APP_TIME_ZONE).format(),
      }],
      taskRemarks: '',
      taskBlockOptions: [],
      inputValues: [],
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'Compose message',
      },
      customFields: [],
      customFieldsBefore: [],
      taskListOfWorkFlow: [],
      existingTaskList: [],
      currentKey: '',
      formOptions: [],
      inputTypeOptions: [
        { title: 'Text Input', code: 'text' },
        { title: 'Date Picker', code: 'date-picker' },
        { title: 'Time Picker', code: 'time-picker' },
        { title: 'Text Area', code: 'textarea' },
        { title: 'File Upload', code: 'file' },
        { title: 'Single Selection', code: 'radio-single' },
        { title: 'Multiple Selection', code: 'radio-multiple' },
        { title: 'Dropdown with Datasource', code: 'datasource-select' },
        { title: 'Dropdown with Data from Another Block', code: 'block-select' },
      ],
      dataSourceOptions: [
        { title: 'users.name', code: 'users.name' },
        { title: 'users.email', code: 'users.email' },
      ],
      fileTypeOptions: [
        { title: 'JPEG Images', code: 'image/jpeg' },
        { title: 'PNG Image', code: 'image/png' },
        { title: 'CSV File', code: 'text/csv' },
        { title: 'Microsoft Word File', code: 'application/msword' },
        { title: 'Microsoft Word (OpenXML (.docx)) File', code: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
        { title: 'PDF File', code: 'application/pdf' },
        { title: 'Microsoft PowerPoint File', code: 'application/vnd.ms-powerpoint' },
        { title: 'Microsoft PowerPoint (OpenXML (.pptx)) File', code: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' },
        { title: 'Microsoft Excel File', code: 'application/vnd.ms-excel' },
        { title: 'Microsoft Excel (OpenXML (.xlsx)) File', code: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
      ],
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],

      required,
    }
  },
  async created() {
    await this.$http.get(`operation/work-flows/${this.$route.params.parent}/show`)
      .then(response => {
        this.formOptions = response.data.forms || []
        this.existingTaskList = response.data.taskLists || []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('operation/task-block-components/get-all')
      .then(response => {
        this.taskBlockOptions = response.data.taskBlockOptions || {}
        this.currentTasks = response.data.currentTasks
        this.currentTasks.forEach(task => {
          task.taskBlocks.forEach(element => {
            const moment1 = moment(element.createdAt).startOf('month')
            const moment2 = moment().startOf('month')
            if (moment1.isSame(moment2, 'date')) {
              this.blockCount += 1
            }

            if (task.workFlow === this.$route.params.parent) {
              // eslint-disable-next-line prefer-destructuring
              const value = element.fieldValues[0].value
              this.taskListOfWorkFlow.push(value)
            }

            const block = this.taskBlockOptions.find(o => o._id === element.block)
            // eslint-disable-next-line no-param-reassign
            element.blockFull = block
          })
        })

        // this.status = this.block.status

        // this.block.fields.forEach(arrayItem => {
        //   const newObj = {}
        //   newObj.key = arrayItem.label
        //   newObj.value = ''
        //   this.inputValues.push(newObj)
        // })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    if (!this.savedData) {
      this.$swal({
        title: 'Unsaved Changes - Leave?',
        html: 'You have unsaved changes. Are you sure you want to leave this page?',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        imageWidth: 80,
        imageHeight: 80,
        imageAlt: 'Custom Icon',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: "Yes, I'm Sure",
        cancelButtonText: 'No, Go Back',
        customClass: {
          confirmButton: 'btn btn-outline-primary no-focus-bg ml-1',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            next()
          } else {
            next(false)
          }
        })
    } else {
      next()
    }
  },
  methods: {
    moveField(from, to) {
      this.customFields.move(from, to)
    },

    moveTask(from, to) {
      this.taskBlocks.move(from, to)
      this.regenerateBlockID()
    },

    setTaskBlock(key) {
      const block = this.taskBlockOptions.find(o => o._id === this.taskBlocks[key].block)
      this.taskBlocks[key].blockFull = block
      this.taskBlocks[key].fieldValues = []

      block.fields.forEach(arrayItem => {
        const newObj = {}
        newObj.key = arrayItem.label
        if (arrayItem.label === 'Block ID') {
          const count = this.blockCount + this.taskBlocks.length
          newObj.value = `TB${moment().format('YY')}${moment().format('MM')}${count.toString().padStart(3, '0')}`
        } else {
          newObj.value = ''
        }
        this.taskBlocks[key].fieldValues.push(newObj)
      })
      if (block.name === 'Forms' || block.name === 'Inventory Request') {
        const newObj = {}
        newObj.key = 'Forms'
        newObj.value = []
        this.taskBlocks[key].fieldValues.push(newObj)
      }
    },
    setTaskBlockCustomFields(key) {
      this.currentKey = key
      const fields = this.taskBlocks[key].customFields
      const copy = [...fields]
      this.customFields = copy
    },
    addNewTaskBlock() {
      this.taskBlocks.push({
        block: '',
        blockFull: {},
        fieldValues: [],
        customFields: [],
        createdAt: moment().utcOffset(process.env.VUE_APP_TIME_ZONE).format(),
      })
    },
    setFinalCustomFields(e) {
      e.preventDefault()
      this.$refs.customFieldsForm.validate().then(success => {
        if (success) {
          this.taskBlocks[this.currentKey].customFields = this.customFields
          this.$refs['add-input-fields-modal'].hide()
        }
      })
    },
    submitForm() {
      this.$refs.workFlowStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`operation/task-block-components/${this.$route.params.id}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.block.status = response.data.data.status || 'active'
              this.status = this.block.status
              this.$swal({
                title: 'Status Updated!',
                html: 'The status has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },

    setRecipients(tkey, key) {
      const { value } = this.taskBlocks[tkey].fieldValues[key]
      this.taskBlocks[tkey].customFields = []
      if (['Send Email With Attachment', 'Send SMS Message', 'Send Telegram Message'].includes(this.taskBlocks[tkey].blockFull.name)) {
        if (value === 'pre-defined recipient') {
          this.taskBlocks[tkey].customFields.push({
            type: 'tag',
            label: 'Pre-defined recipient(s)',
            value: [],
            extraOptions: [],
            required: 'yes',
            placeholder: 'Add recipients here',
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
          })
        } else if (value === 'data-source') {
          this.taskBlocks[tkey].customFields.push({
            type: 'select',
            label: 'Select Data Source',
            value: '',
            extraOptions: [
              {
                key: 'User',
                value: 'User',
              },
              {
                key: 'Service Form',
                value: 'Service Form',
              },
            ],
            required: 'yes',
            placeholder: 'Select Data Source',
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
          })

          this.taskBlocks[tkey].customFields.push({
            type: 'select',
            label: 'Select Recipient',
            value: '',
            extraOptions: [],
            required: 'yes',
            placeholder: 'Select Recipient',
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
          })
        } else if (value === 'another block') {
          this.taskBlocks[tkey].customFields.push({
            type: 'auto-suggest',
            label: 'Search Block ID',
            value: '',
            extraOptions: [{
              data: this.taskListOfWorkFlow,
            }],
            required: 'yes',
            placeholder: 'Key in Block ID or Search here',
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
          })

          this.taskBlocks[tkey].customFields.push({
            type: 'select',
            label: 'Select Recipient',
            value: '',
            extraOptions: [],
            required: 'yes',
            placeholder: 'Select Recipient',
            typeInvalid: false,
            typeInvalidMessage: '',
            labelInvalid: false,
            labelInvalidMessage: '',
            placeholderInvalid: false,
            placeholderInvalidMessage: '',
            requiredInvalid: false,
            requiredInvalidMessage: '',
          })
        }
      }
    },

    setFinalRecipients(tkey, key, rindex) {
      const { value } = this.taskBlocks[tkey].fieldValues[key]
      const sourceType = this.taskBlocks[tkey].customFields[rindex].value
      const block = this.taskBlocks[tkey].blockFull.name
      if (value === 'data-source') {
        if (sourceType === 'Service Form') {
          this.taskBlocks[tkey].customFields[rindex + 1].value = ''
          this.taskBlocks[tkey].customFields[rindex + 1].extraOptions = []
          if (block === 'Send Email With Attachment') {
            this.taskBlocks[tkey].customFields[rindex + 1].extraOptions = [
              {
                key: 'Customer',
                value: 'Customer',
              },
            ]
          } else if (block === 'Send SMS Message' || block === 'Send Telegram Message') {
            this.taskBlocks[tkey].customFields[rindex + 1].extraOptions = [
              {
                key: 'Customer',
                value: 'Customer',
              },
              {
                key: 'Agent',
                value: 'Agent',
              },
            ]
          }
        } else if (sourceType === 'User') {
          this.taskBlocks[tkey].customFields[rindex + 1].value = ''
          this.taskBlocks[tkey].customFields[rindex + 1].extraOptions = []
          this.$http.get('operation/task-block-components/respond-with/user-options')
            .then(response => {
              response.data.users.forEach(arrayItem => {
                const newObj = {}

                newObj.key = arrayItem._id
                newObj.value = arrayItem.name
                this.taskBlocks[tkey].customFields[rindex + 1].extraOptions.push(newObj)
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      }
    },
    suggestOnInputChange(text, tkey, key, rindex) {
      if (text === '' || text === undefined) {
        return
      }

      console.log(key, rindex)
      // const filteredData = this.taskBlocks[tkey].customFields[rindex].extraOptions[0].data.filter(item => item.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, 10)

      // this.taskBlocks[tkey].customFields[rindex].extraOptions = [{
      //   data: filteredData,
      // }]
    },

    validator(tag, task) {
      if (task.blockFull.name === 'Send Email With Attachment') {
        return this.validateEmail(tag)
      }
      if (task.blockFull.name === 'Send SMS Message') {
        return this.validatePhone(tag)
      }
      if (task.blockFull.name === 'Send Telegram Message') {
        return this.validateTelegramPhone(tag)
      }
      return true
    },
    validateEmail(inputText) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (inputText.match(mailformat)) {
        return true
      }
      return false
    },

    validatePhone(inputText) {
      // eslint-disable-next-line no-restricted-globals
      return inputText.length === 8 && !isNaN(inputText)
    },
    validateTelegramPhone(inputText) {
      // eslint-disable-next-line no-restricted-globals
      return (inputText.length)
    },

    setBlock(suggestionItem, suggestionIndex, tkey, key, rindex) {
      this.taskBlocks[tkey].customFields[rindex].value = suggestionItem.item
      this.taskBlocks[tkey].customFields[rindex + 1].extraOptions = []
      const block = this.currentTasks.find(task => task.taskBlocks.find(element => element.fieldValues[0].value === suggestionItem.item))
      const taskBlock = block.taskBlocks.find(element => element.fieldValues[0].value === suggestionItem.item)
      taskBlock.blockFull.fields.forEach(arrayItem => {
        if (arrayItem.type === 'text') {
          const newObj = {}
          newObj.key = arrayItem.label
          newObj.value = arrayItem.label
          this.taskBlocks[tkey].customFields[rindex + 1].extraOptions.push(newObj)
        }
      })
    },

    addCustomField() {
      this.customFields.push({
        type: 'text',
        label: '',
        extraOptions: [],
        required: 'yes',
        file: null,
        base64File: null,
        placeholder: '',
        typeInvalid: false,
        typeInvalidMessage: '',
        fileInvalid: false,
        fileInvalidMessage: '',
        labelInvalid: false,
        labelInvalidMessage: '',
        placeholderInvalid: false,
        placeholderInvalidMessage: '',
        requiredInvalid: false,
        requiredInvalidMessage: '',
      })
    },
    addCustomFieldOptions(index) {
      this.customFields[index].extraOptions.push({
        key: '',
        keyInvalid: false,
        keyInvalidMessage: '',
        value: '',
        valueInvalid: false,
        valueInvalidMessage: '',
      })
    },
    updateExtraFileds(index) {
      // eslint-disable-next-line no-unused-expressions
      this.customFields[index].inputTypeInvalid === true ? this.customFields[index].inputTypeInvalid = false : null
      if (['radio-single', 'radio-multiple', 'datasource-select', 'block-select', 'file'].includes(this.customFields[index].type)) {
        this.customFields[index].extraOptions.push({
          key: '',
          keyInvalid: false,
          keyInvalidMessage: '',
          value: '',
          valueInvalid: false,
          valueInvalidMessage: '',
        })
      } else {
        this.customFields[index].extraOptions = []
      }
    },
    removeCustomFieldOptions(index, key) {
      if (this.customFields[index].extraOptions.length > 1) {
        this.customFields[index].extraOptions.splice(key, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeCustomField(index) {
      this.customFields.splice(index, 1)
    },
    removeFromTask(index) {
      this.taskBlocks.splice(index, 1)
      this.regenerateBlockID()
    },
    regenerateBlockID() {
      this.taskBlocks.forEach((task, key) => {
        task.fieldValues.forEach((arrayItem, index) => {
          if (arrayItem.key === 'Block ID') {
            const count = this.blockCount + key + 1
            this.taskBlocks[key].fieldValues[index].value = `TB${moment().format('YY')}${moment().format('MM')}${count.toString().padStart(3, '0')}`
          }
        })
      })
    },
    submitTaskForm() {
      this.$refs.addTaskForm.validate().then(async success => {
        if (success) {
          const duplicate = false
          // let blockID = ''

          // // eslint-disable-next-line no-restricted-syntax
          // for (const [key, task] of Object.entries(this.taskBlocks)) {
          //   if (task.blockFull.name === 'Inventory Request') {
          //     const fieldVal = task.fieldValues.find(o => o.key === 'Forms')
          //     if (fieldVal && fieldVal.value) {
          //       // eslint-disable-next-line no-loop-func
          //       const existInNewTask = this.taskBlocks.some((o, i) => {
          //         // eslint-disable-next-line eqeqeq
          //         if (key == i) return false
          //         if (o.blockFull.name !== 'Inventory Request') return false
          //         const oFieldVal = o.fieldValues.find(v => v.key === 'Forms')
          //         if (oFieldVal && oFieldVal.value && oFieldVal.value === fieldVal.value) {
          //           blockID = o.fieldValues.find(v => v.key === 'Block ID').value
          //           return true
          //         }
          //         return false
          //       })

          //       // eslint-disable-next-line no-loop-func, arrow-body-style
          //       const existInOldTask = this.existingTaskList.some(t => {
          //         return t.taskBlocks.some(o => {
          //           if (!o.block || o.block.name !== 'Inventory Request') return false
          //           const oFieldVal = o.fieldValues.find(v => v.key === 'Forms')
          //           if (oFieldVal && oFieldVal.value && oFieldVal.value === fieldVal.value) {
          //             blockID = o.fieldValues.find(v => v.key === 'Block ID').value
          //             return true
          //           }
          //           return false
          //         })
          //       })

          //       if (existInNewTask || existInOldTask) {
          //         duplicate = true
          //         break
          //       }
          //     }
          //   }
          // }

          // if (duplicate) {
          //   this.$swal({
          //     title: 'Unable to Save Workflow Task.',
          //     html: `Redemption of Imported Urn Form has been tagged to another Inventory Request <strong>${blockID}</strong>. The form can only be attached to a single Inventory Request workflow task.`,
          //     // eslint-disable-next-line global-require
          //     imageUrl: require('@/assets/images/icons/warning.png'),
          //     imageWidth: 80,
          //     imageHeight: 80,
          //     imageAlt: 'Custom Icon',
          //     showCancelButton: false,
          //     allowOutsideClick: false,
          //     confirmButtonText: 'Got It',
          //     customClass: {
          //       confirmButton: 'btn btn-primary',
          //     },
          //     buttonsStyling: false,
          //   })
          // }

          if (!duplicate) {
            const formData = new FormData()

            formData.append('taskName', this.taskName)
            formData.append('taskRemarks', this.taskRemarks)
            const trimmedData = this.taskBlocks.map(field => {
              const properties = {
                block: field.block,
                fieldValues: field.fieldValues,
                customFields: field.customFields,
                createdAt: moment().utcOffset(process.env.VUE_APP_TIME_ZONE).format(),
              }
              return properties
            })
            formData.append('taskBlocks', JSON.stringify(trimmedData))

            this.$http.post(`operation/work-flows/${this.$route.params.parent}/task/store`, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(response => {
                if (response.data.data) {
                  this.$swal({
                    title: 'Task Added in Workflow.',
                    html: response.data.message || '',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        this.savedData = true
                        this.$router.push({ name: 'operation-workflows-show', params: { id: this.$route.params.parent } })
                      }
                    })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.message || '',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
              .catch(error => {
                if (error.data.errors) {
                  error.data.errors.forEach(validationError => {
                    if (validationError.param === 'name') {
                      this.nameError = validationError.msg
                      this.nameValidation = true
                    } else if (validationError.param === 'status') {
                      this.statusError = validationError.msg
                      this.statusValidation = true
                    } else if (validationError.param === 'nextStatus') {
                      this.nextStatusError = validationError.msg
                      this.nextStatusValidation = true
                    } else if (validationError.param === 'description') {
                      this.descriptionError = validationError.msg
                      this.descriptionValidation = true
                    } else if (validationError.param === 'approvers') {
                      this.approversError = validationError.msg
                      this.approversValidation = true
                    } else {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                          icon: 'AlertTriangleIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })

                  const items = document.getElementsByClassName('text-danger')
                  window.scrollTo({
                    top: items[0].offsetTop,
                    behavior: 'smooth',
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                }
              })
          }
        }
      })
    },
  },
}
</script>

<style scoped>
  .padding-two {
    padding: 2rem !important;
  }
  .padding-one {
    padding: 1.5rem 1.5rem 0 1.5rem !important;
  }
  .shadow-background {
    background: #F5F8FB;
  }
  .purple-button {
    background: #9B5899 !important;
  }
  .purple-row {
    background: rgba(155, 88, 153, 0.12);
  }
  .no-background {
    background: none !important;
  }
  .background-card {
    background: #9FB8D8;
    border-radius: 6px;
  }
  .shadow-body {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 1.5rem !important;
  }
  .red-trash-button {
    color: #D91B35 !important;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }

  .link-tag {
    color: #104D9D;
  }
</style>
